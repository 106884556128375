import classNames from 'classnames';
import {useEffect} from 'react';
import {useForm, ValidateResult} from 'react-hook-form';

import {Button, BUTTON_STYLES} from '@/components/base/button/Button';
import {INPUT_STYLES} from '@/components/base/input/types';
import {RequiredInput} from '@/components/base/required-input/RequiredInput';
import {Textarea, TEXTAREA_STYLES} from '@/components/base/textarea/Textarea';
import {Additions} from '@/components/common/forms/additions/Additions';
import {getQuestions, getRecaptchaDisclaimer} from '@/components/common/forms/request-form/helpers';
import styles from '@/components/common/forms/request-form/RequestForm.module.css';
import {withRecaptcha} from '@/hocs/with-recaptcha';
import {RequestPostData} from '@/services/api/interfaces/request';
import {setGAClientId} from '@/utilites/analytics';
import {getFormError} from '@/utilites/get-form-error/get-form-error';
import {validateEmail} from '@/utilites/validate-email/validate-email';
import {validatePhone} from '@/utilites/validate-phone/validate-phone';

interface RequestFormProps {
	onSubmit?: (data: RequestPostData) => void;
	onClick?: () => void;
	onClose?: () => void;
	autoFocusFirstInput?: boolean;
	buttonText?: string;
	buttonClassName?: string;
	inputStyle?: INPUT_STYLES;
	textareaStyle?: TEXTAREA_STYLES;
	buttonStyle?: BUTTON_STYLES;
	controlClassName?: string;
	contactsClassName?: string;
	buttonWrapperClassName?: string;
	className?: string;
	commentText?: string;
	disclaimer?: JSX.Element | string;
	feedbackQuestions?: JSX.Element | string;
}

const phoneId = 'phone';
const emailId = 'email';
const nameId = 'name';
const companyId = 'company';
const messageId = 'message';

function PureRequestForm({
	onSubmit,
	onClick,
	autoFocusFirstInput,
	buttonText,
	buttonClassName,
	buttonStyle,
	inputStyle,
	textareaStyle,
	controlClassName,
	contactsClassName,
	buttonWrapperClassName,
	className,
	commentText,
	disclaimer = getRecaptchaDisclaimer(),
	feedbackQuestions = getQuestions(),
}: RequestFormProps) {
	const {register, handleSubmit, reset, formState} = useForm();
	const {errors, isSubmitSuccessful} = formState;

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	useEffect(() => {
		setGAClientId();
	}, []);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={className}>
			<div className={classNames(styles.contacts, contactsClassName)}>
				<RequiredInput
					id={phoneId}
					autoFocus={autoFocusFirstInput}
					autocomplete="tel"
					placeholder="Телефон"
					controlClassName={classNames(styles.control, [styles.contactsControl, controlClassName])}
					inputStyle={inputStyle}
					registerProps={register(phoneId, {
						required: true,
						validate: (value): ValidateResult =>
							validatePhone(value) ? true : 'Некорректный номер телефона',
					})}
					error={getFormError(errors[phoneId])}
				/>
				<RequiredInput
					id={emailId}
					autocomplete="email"
					placeholder="Электронная почта"
					controlClassName={classNames(styles.control, [styles.contactsControl, controlClassName])}
					inputStyle={inputStyle}
					registerProps={register(emailId, {
						required: true,
						validate: (value): ValidateResult =>
							validateEmail(value) ? true : 'Некорректный адрес электронной почты',
					})}
					error={getFormError(errors[emailId])}
				/>
			</div>
			<RequiredInput
				id={nameId}
				autocomplete="name"
				placeholder="Ваше имя"
				registerProps={register(nameId, {required: true})}
				controlClassName={classNames(styles.control, controlClassName)}
				inputStyle={inputStyle}
				error={getFormError(errors[nameId])}
			/>
			<RequiredInput
				id={companyId}
				autocomplete="organization"
				placeholder="Компания"
				registerProps={register(companyId, {required: true})}
				controlClassName={classNames(styles.control, controlClassName)}
				inputStyle={inputStyle}
				error={getFormError(errors[companyId])}
			/>
			<Textarea
				id="comment"
				className={classNames(styles.control, controlClassName)}
				textareaStyle={textareaStyle}
				rows={3}
				{...(commentText ? {value: commentText} : {})}
				placeholder="Расскажите о своей задаче или задайте вопрос"
				error={getFormError(errors[messageId])}
				{...register(messageId, {required: true})}
			/>
			{(feedbackQuestions || disclaimer) && (
				<Additions feedbackQuestions={feedbackQuestions} disclaimer={disclaimer} />
			)}
			<div className={classNames(styles.buttonWrapper, buttonWrapperClassName)}>
				<Button
					type="submit"
					className={classNames(styles.button, buttonClassName)}
					style={buttonStyle}
					onClick={onClick}
				>
					{buttonText ?? 'Задать вопрос'}
				</Button>
			</div>
		</form>
	);
}

export const RequestForm = withRecaptcha(PureRequestForm);
