import {MakeCommonResponseInterface} from '@/services/http-adapter/interfaces';

export enum ServiceLandingName {
	Workspace = 'landing-b2b',
	Workmail = 'landing-mail',
	WorkMessenger = 'landing-messenger',
	Workdisk = 'landing-teambox',
	office = 'landing-office',
	MyTeams = 'landing-myteam',
	Contacts = 'landing-contacts',
	Onpremise = 'landing-onpremise',
	Education = 'landing-education',
	Tariffs = 'landing-tariffs',
	Partners = 'landing-partners',
	Promo = 'landing-promo',
	Download = 'landing-download',
	Retail = 'landing-retail',
	Meetings = 'landing-meetings',
	Security = 'landing-security',
	SMB = 'landing-smb',
}

export enum RequestRate {
	AllIn = 'all-in',
}

export interface RequestPostData {
	service: ServiceLandingName;
	name: string;
	company: string;
	email: string;
	phone: string;
	message?: string;
	rate?: RequestRate;
	token?: string;
}

export interface RequestFeedbackPostData {
	landing_name: ServiceLandingName;
	subject: string;
	data: Record<string, string>;
}

export interface RequestRespondentsPostData {
	name: string;
	company: string;
	email: string;
	phone: string;
	message?: string;
}

export interface RequestSuccessResponse {}

export interface RequestErrorResponse {}

export type TRequestResponse = MakeCommonResponseInterface<RequestSuccessResponse, RequestErrorResponse>;
